;(function () {
    'use strict';

    document
        .querySelectorAll('.js-pcat-service-functions input[type=checkbox]')
        .forEach(checkbox => {
            checkbox.addEventListener('change', function(e) {
                const checkboxes = document.querySelectorAll('.js-pcat-service-functions input[type=checkbox]');
                const checkedCount = Array.from(checkboxes).filter(checkbox => checkbox.checked).length;
                checkboxes.forEach(checkbox => {
                    checkbox.disabled = checkedCount >= 5 && !checkbox.checked;
                });
            });
        });
}());
