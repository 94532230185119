;(function () {
    'use strict';
    var MaterialIcons400 = new FontFaceObserver("Material Icons", {weight: 400, style: "normal"});
    Promise.all([MaterialIcons400.load()])
    .then(function() {
        document.documentElement.classList.add("icons-loaded");
        if (typeof(Storage) !== "undefined") {
            sessionStorage.setItem("iconsLoaded", "true");
        }
    })
    .catch(function (err) {
        document.documentElement.classList.remove("icons-loaded");
        if (typeof(Storage) !== "undefined") {
            sessionStorage.setItem("iconsLoaded", "false");
        }
        console.warn("iconcheck: Material icons font did not load:", err);
    });
}());
