;(function () {
    'use strict';

    document
        .querySelectorAll('.js-cadts')
        .forEach(component => {
            const componentHd = component.querySelector('.js-cadts-hd');
            if (!componentHd) return;

            const updateHeight = (height) => {
                document.documentElement.style.setProperty('--cadts-hd-height', `${height}px`);
                console.log(`Updated height: ${height}px`);
            };

            const throttle = (func, limit) => {
                let lastFunc;
                let lastRan;
                return function() {
                    const context = this;
                    const args = arguments;
                    if (!lastRan) {
                        func.apply(context, args);
                        lastRan = Date.now();
                    } else {
                        clearTimeout(lastFunc);
                        lastFunc = setTimeout(function() {
                            if ((Date.now() - lastRan) >= limit) {
                                func.apply(context, args);
                                lastRan = Date.now();
                            }
                        }, limit - (Date.now() - lastRan));
                    }
                }
            };

            const observer = new ResizeObserver(entries => {
                for (let entry of entries) {
                    const newHeight = entry.contentRect.height;
                    throttle(() => updateHeight(newHeight), 100)(); // Adjust the 100 ms to control throttling
                }
            });

            observer.observe(componentHd);
            component.classList.add('is-sticky');
        });

    document
        .querySelectorAll('select.js-capability-indicator-rating-field')
        .forEach(select => {
            select.addEventListener('invalid', function (event) {
                if (event.target.validity.valueMissing) {
                    const message = event.target.getAttribute('data-invalid-message');
                    if (message) {
                        event.target.setCustomValidity(message);
                    }
                }
            })
            select.addEventListener('change', function (event) {
                event.target.setCustomValidity('');
            })
        });
}());
